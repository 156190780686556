import { Button, Space, Table } from "antd";
import Column from "antd/es/table/Column";
import { TableRowSelection } from "antd/es/table/interface";
import { TableActionHeader } from "components/Global";
import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "redux/store";
import { DeleteActionSvg, SortSvgBottom, SortSvgTop } from "utils/svgs";
import styles from "./index.module.scss";
import SearchAndFilter from "./SearchFilter";
// import { deleteBillAction, getBillPaymentAction, postedBillAction, billPaymentSync } from "redux/action/billPaymentAction";
import ConfirmDelete from "components/Global/confirmDeleteModel";
import moment from "moment";
import {
  billPaymentSync,
  deleteBillAction,
  getBillPaymentAction,
  postedBillAction,
} from "redux/action/billPaymentAction";
import { pageSizeOptionsPaging } from "utils/constant";

const SortingIcon = (data: any) => {
  return data.data.sortOrder === "ascend" ? <SortSvgTop /> : <SortSvgBottom />;
};

export interface DataType {
  key: React.Key;
  vendorName: string;
  amount: number;
  type: string;
  modifiedAt: string;
  status: string;
}
const AccountExport = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [filterStatus, setFilterStatus] = useState("all");
  const [filterType, setFilterType] = useState("all");
  const [PageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [filterValue, setFilterValue] = useState<any | null>({
    filterFieldName: null,
    filterFieldValue: null,
    filterFieldValue2: null,
  });
  const [sortDirection, SetsortDirection] = useState<"ascend" | "descend">(
    "ascend"
  );
  const [sortOrder, SetsortOrder] = useState<{
    columnKey?: string;
    order?: "asc" | "desc";
  }>({});
  const [loading, setLoading] = useState(true);
  const [playgroundStartDate, setPlaygroundStartDate] = useState<Date | null>(
    null
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [playgroundEndDate, setPlaygroundEndDate] = useState<Date | null>(null);

  const [filteredData, setFilteredData] = useState([]);
  const [deleteData, setDeleteData] = useState<any>({});
  const [syncData, setSyncData] = useState(
    localStorage.getItem("sync") || "false"
  );

  const dispatch = useDispatch<AppDispatch>();

  const billPaymentData = useSelector((state: any) => state.billPayment);

  useEffect(() => {
    if (billPaymentData.data && billPaymentData.data.length > 0) {
      const finalDarations = billPaymentData.data.map((x: any) => {
        return {
          ...x,
          key: x.id,
        };
      });
      setTotalRecords(billPaymentData.totalPages);

      localStorage.setItem("sync", "true");
      setSyncData("true");

      setFilteredData(finalDarations);

      setLoading(false);
    }
    if (billPaymentData.data && billPaymentData.data.length == 0) {
      setFilteredData([]);
      setLoading(false);
      setTotalRecords(0);
    }
  }, [billPaymentData]);

  const postBill = () => {
    setLoading(true);
    // ajax request after empty completing

    dispatch(postedBillAction(selectedRowKeys))
      .then(() => {
        setSelectedRowKeys([]);

        handleAllFilter();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setPageSize(10);
  };

  const rowSelection: TableRowSelection<DataType> = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const hasSelected = selectedRowKeys.length > 0;

  const handlePlaygroundStartDate = (value: any) => {
    setPlaygroundStartDate(value);
    setPlaygroundEndDate(null);
    setFilterValue({
      ...filterValue,
      filterFieldValue: value
        ? moment(value.$d).startOf("day").toISOString()
        : "",
      filterFieldName: "modifiedDate",
    });
    if (!value) {
      setFilterValue({
        filterFieldValue2: null,
        filterFieldValue: null,
        filterFieldName: null,
      });
    }
  };
  const handlePlaygroundEndDate = (value: any) => {
    setPlaygroundEndDate(value);
    setFilterValue({
      ...filterValue,
      filterFieldValue2: value
        ? moment(value.$d).endOf("day").toISOString()
        : "",
      filterFieldName: "modifiedDate",
    });
  };

  useEffect(() => {
    setLoading(true);
    handleAllFilter();
  }, [PageSize, currentPage, sortOrder]);

  const handleAllFilter = () => {
    setLoading(true);
    let url = `page=${currentPage}&limit=${PageSize}&search=${searchValue}`;

    if (filterStatus !== "all") {
      url += `&type=${filterStatus}`;
    }
    if (filterType !== "all") {
      url += `&status=${filterType}`;
    }
    if (
      playgroundStartDate !== undefined &&
      playgroundEndDate !== undefined &&
      playgroundStartDate != null &&
      playgroundEndDate != null
    ) {
      url += `&startDate=${playgroundStartDate}&endDate=${playgroundEndDate}`;
    }

    if (sortOrder.columnKey && sortOrder.order) {
      url += `&sortField=${sortOrder.columnKey}&sortOrder=${sortOrder.order}`;
    }
    dispatch(
      getBillPaymentAction({
        url,
        isPagination: true,
      })
    );
  };

  const handleDeleteBill = async () => {
    setLoading(true);
    await dispatch(deleteBillAction(deleteData.id)).then(() => {
      setIsModalOpen(false);
      setDeleteData({});
      handleAllFilter();
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const showModal = (rowData: any) => {
    setIsModalOpen(true);
    setDeleteData(rowData);
  };

  const performSearchHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchValue(value);
    setCurrentPage(1);
  };
  const performFilterTypeHandler = (value: any) => {
    setFilterStatus(value);
    setCurrentPage(1);
  };
  const performFilterStatusHandler = (value: any) => {
    setFilterType(value);
    setCurrentPage(1);
  };

  const paginationChangeHandler = (pageNo: number, pageSize: number) => {
    setCurrentPage(pageNo);
    setPageSize(pageSize);
  };
  const modifyPageSize = (current: number, size: number) => {
    if (!size) {
      setPageSize(10);
    } else {
      setPageSize(size);

      setCurrentPage(1);
    }
  };
  const performSortHandler = (type: "ascend" | "descend", columnName: any) => {
    if (type !== sortDirection) {
      SetsortDirection(type);
      SetsortOrder({
        columnKey: columnName,
        order: type === "ascend" ? "asc" : "desc",
      });
      setCurrentPage(1);
    }
  };

  const tableChangeHandler = (pageInfo: any, d: any, columnInfo: any) => {
    performSortHandler &&
      performSortHandler(columnInfo.order, columnInfo.columnKey);
  };
  const syncNow = () => {
    const data: any = [];
    dispatch(billPaymentSync(data)).then(() => {
      localStorage.setItem("syncNowKey", "true");
      setSyncData("true");
      handleAllFilter();
    });
  };

  return (
    <>
      {/* {loading ? <Loader /> : */}
      <div className={styles["role-table"]}>
        <TableActionHeader title={"Accounting Export"}>
          <div className={styles["role-table__action"]}>
            <Button
              type="primary"
              onClick={postBill}
              disabled={!hasSelected}
              loading={billPaymentData.isLoading}
            >
              Posted
            </Button>
            {syncData === "false" && (
              <Button
                type="primary"
                style={{ marginLeft: "10px" }}
                onClick={syncNow}
                loading={billPaymentData.isLoading}
              >
                syncNow
              </Button>
            )}
          </div>
        </TableActionHeader>
        <div className={styles["dynamic-table"]}>
          <SearchAndFilter
            performSearchHandler={performSearchHandler}
            searchValue={searchValue}
            performFilterHandler={performFilterTypeHandler}
            performFilterStatusHandler={performFilterStatusHandler}
            filterValue={filterValue}
            PageSize={PageSize}
            handlePlaygroundStartDate={handlePlaygroundStartDate}
            playgroundStartDate={playgroundStartDate}
            handlePlaygroundEndDate={handlePlaygroundEndDate}
            playgroundEndDate={playgroundEndDate}
            handleAllFilter={handleAllFilter}
            loading={loading}
          />
          <Table
            dataSource={filteredData}
            rowSelection={{
              ...rowSelection,
              getCheckboxProps: (record: DataType) => ({
                disabled: record.status === "Posted", // Disable checkbox if status is 'posted'
              }),
            }}
            pagination={{
              total: totalRecords,
              current: currentPage,
              onChange: paginationChangeHandler,
              pageSize: PageSize,
              pageSizeOptions: pageSizeOptionsPaging,
              showSizeChanger: true,
              onShowSizeChange: modifyPageSize,
            }}
            rowKey="key"
            loading={loading}
            className="table-global"
            onChange={tableChangeHandler}
            scroll={{ y: "calc(70vh - 190px)", x: "63vh" }}
          >
            <Column
              title="vendorName"
              dataIndex="vendorName"
              key="vendorName"
              width={"15%"}
              sortDirections={["ascend", "descend", "ascend"]}
              className="bg-white"
              defaultSortOrder={sortDirection}
              sorter={() => {
                return null as any;
              }}
              sortIcon={(data) => <SortingIcon data={data} />}
            />
            <Column
              title="Amount"
              dataIndex="totalAmount"
              key="totalAmount"
              width={"25%"}
              className="bg-white"
              render={(value, rowData: any) => {
                return (
                  <div
                    className={
                      rowData.totalAmount > 0
                        ? styles["dynamic-table__amountGreen"]
                        : styles["dynamic-table__amountRed"]
                    }
                  >
                    {rowData.currency} {rowData.totalAmount}
                  </div>
                );
              }}
            />
            <Column
              title="Due Date"
              dataIndex="dueDate"
              key="dueDate"
              width={"25%"}
              sortDirections={["ascend", "descend", "ascend"]}
              defaultSortOrder={sortDirection}
              sorter={() => {
                return null as any;
              }}
              sortIcon={(data) => <SortingIcon data={data} />}
              className="bg-white"
            />
            <Column
              title="createdAtBill"
              dataIndex="createdAtBill"
              key="createdAtBill"
              width={"25%"}
              className="bg-white"
              sortDirections={["ascend", "descend", "ascend"]}
              defaultSortOrder={sortDirection}
              sorter={() => {
                return null as any;
              }}
              render={(value, rowData: any) => {
                return (
                  <div>
                    {rowData.createdAtBill
                      ? moment(rowData.createdAtBill).format("YYYY-MM-DD")
                      : ""}
                  </div>
                );
              }}
              sortIcon={(data) => <SortingIcon data={data} />}
            />
            <Column
              title="status"
              dataIndex="status"
              key="status"
              width={"25%"}
              className="bg-white"
              render={(value, rowData: any) => {
                return (
                  <div
                    className={
                      rowData.status === "Posted"
                        ? styles["dynamic-table__statusGreen"]
                        : ""
                    }
                  >
                    {rowData.status}
                  </div>
                );
              }}
            />

            <Column
              title="Action"
              dataIndex="action"
              key="action"
              className="bg-white"
              width={"20%"}
              render={(value, rowData: any) => {
                const url =
                  rowData.type === "XEROONLINE"
                    ? `https://go.xero.com/AccountsPayable/View.aspx?InvoiceID=${rowData.billId}`
                    : `https://sandbox.qbo.intuit.com/app/bill?txnId=${rowData.billId}`;
                return (
                  <Space size={20}>
                    <div
                      className={styles["dynamic-table__delete-icon"]}
                      style={{ marginRight: "2rem" }}
                      onClick={() => showModal(rowData)}
                    >
                      <DeleteActionSvg />
                    </div>
                    {rowData.billId ? (
                      <a href={url} target="_blank" rel="noreferrer">
                        view in books
                      </a>
                    ) : (
                      ""
                    )}
                  </Space>
                );
              }}
            />
          </Table>
        </div>
        <ConfirmDelete
          handleCancel={handleCancel}
          handleOk={handleOk}
          isModalOpen={isModalOpen}
          deleteHandler={handleDeleteBill}
          loading={billPaymentData.isLoading}
        />
      </div>
      {/* } */}
    </>
  );
};

export default AccountExport;
