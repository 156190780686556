import { createAsyncThunk } from "@reduxjs/toolkit";
import { deleteApi, getApi, postApi } from "redux/apis";


export const getBillPaymentAction = createAsyncThunk(
    "billPayment",
    async (data: any, { rejectWithValue }) => {

        const { params, url } = data;
        try {
            const response = await getApi(
                `/billPayment?${url}`,
                params
            );

            return response.data;
        } catch (error: any) {
            if (!error.response) {
                throw error;
            }
            return rejectWithValue(error?.response?.data);
        }
    }
);



export const deleteBillAction = createAsyncThunk(
    "billPayment/delete",
    async (data: any, { rejectWithValue }) => {


        try {
            const response = await deleteApi(
                `/billPayment/${data}`,
            );

            return response.data;
        } catch (error: any) {
            if (!error.response) {
                throw error;
            }
            return rejectWithValue(error?.response?.data);
        }
    }
);

export const postedBillAction = createAsyncThunk(
    "billPayment/posted",
    async (data: any, { rejectWithValue }) => {


        try {
            const response = await postApi(
                `/billPayment/post`, { id: data }
            );

            return response.data;
        } catch (error: any) {
            if (!error.response) {
                throw error;
            }
            return rejectWithValue(error?.response?.data);
        }
    }
);


export const billPaymentSync = createAsyncThunk(
    "billPayment/sync",
    async (data: any, { rejectWithValue }) => {


        try {
            const response = await postApi(
                `/billPayment/seed`,
            );

            return response.data;
        } catch (error: any) {
            if (!error.response) {
                throw error;
            }
            return rejectWithValue(error?.response?.data);
        }
    }
);

