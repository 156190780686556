import { SearchOutlined } from "@ant-design/icons";
import { Col, Input, Row, Select, Space, DatePicker, Button } from "antd";
import { FC } from "react";
import styles from "./index.module.scss";
import { SearchAndFilterProps } from "./types";

// For search filter and paginate
const SearchAndFilter: FC<SearchAndFilterProps> = (props) => {
  // Init
  const { performSearchHandler, searchValue, performFilterStatusHandler, playgroundEndDate, playgroundStartDate, handlePlaygroundEndDate, handlePlaygroundStartDate, handleAllFilter, loading } = props;
  const disabledDate = (current: any) => {
    // Disable future dates
    return current && current > new Date();
  };
  // JSX
  return (
    <div className={styles["search-filter"]}>
      <div className={styles["search-filter__wrapper"]}>
        <Row justify={"space-between"}>
          <Col></Col>
          <Col>
            <Space>
              <Input
                className={styles["search-filter__search"]}
                placeholder="Search by Vendor Name"
                suffix={<SearchOutlined />}
                onChange={performSearchHandler}
                value={searchValue}
                size="large"
              />
              {/* <Select
                defaultValue="all"
                onChange={performFilterHandler}
                style={{ width: 200 }}
                options={[
                  { label: "All", value: "all" },
                  { label: "Expense", value: "expense" },
                  { label: "Bill", value: "bill" },
                  { label: "Bill Payment", value: "bill_payment" },
                ]}
                size="large"
              /> */}
              <Select
                defaultValue="all"
                onChange={performFilterStatusHandler}
                style={{ width: 200 }}
                options={[
                  { label: "All", value: "all" },
                  { label: "Posted", value: "Posted" },
                  { label: "Pending", value: "Pending" },

                ]}
                size="large"
              />
              <DatePicker
                style={{ width: 200 }}
                placeholder="Start date"
                onChange={handlePlaygroundStartDate}
                value={playgroundStartDate}
                disabledDate={disabledDate}
              />
              <DatePicker
                style={{ width: 200 }} 
                placeholder="End date"
                value={playgroundEndDate}
                onChange={handlePlaygroundEndDate}
                disabledDate={(current: any) =>
                  current && (current < playgroundStartDate || current > new Date())
                }
                disabled={!playgroundStartDate}
              />
              <Button
                onClick={handleAllFilter}
                type="primary"
                loading={loading}

              >
                {`Fetch `}
              </Button>
            </Space>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default SearchAndFilter;
