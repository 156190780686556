import CallbackComponent from "components/src/pages/CallBack";
import { SaveConnectionAction } from "redux/action/saveConnectionAction";
import { useDispatch } from "react-redux";
import { AppDispatch } from "redux/store";
const CallBack = () => {
    const dispatch = useDispatch<AppDispatch>();
    const updateApiData = (data: any) => {

        dispatch(SaveConnectionAction(data));

    }



    return (<CallbackComponent updateApiData={updateApiData} />);
}

export default CallBack;