import { RegistrationLayout } from "layouts";
import { FORMDATA } from "constants/Data";
import { loginAction } from "redux/action/loginAction";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { RegisterLayoutBody } from "components/Register";
import { getApi } from "redux/apis";
import { registerAction } from "redux/action/registerAction";
import { toastText } from "utils/utils";

// Register page
const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [isIntuitLoading, setIsIntuitLoading] = useState(false);
  const [isXeroLoading, setIsXeroLoading] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState("");

  const [phoneError, setPhoneError] = useState("");

  const [isPasswordValidate, setIsPasswordValidate] = useState(false);
  const onSubmit = (values: any) => {
    if (phoneError === "" && isPasswordValidate) {
      const newValues = {
        ...values,
        phone: phoneNumber,
      };
      setIsLoading(true);
      setIsXeroLoading(false);
      setIsIntuitLoading(false);

      const { email } = values;

      getApi(`/auth/get-email`, { email })
        .then((res: any) => {
          if (res?.data?.data === null) {
            dispatch(registerAction(newValues) as any)
              .unwrap()
              .then((res: any) => {
                if (res.statusCode === 200) {
                  toastText(
                    "Registration successfully, Please check your inbox to verify your email. ",
                    "success"
                  );
                  navigate("/login");
                }
              })
              .catch((error: any) => {
                setIsLoading(false);
                setIsXeroLoading(false);
                setIsIntuitLoading(false);
                navigate("/register");
                toastText(error.message, "error");
              });
          } else {
            toastText("User Already exist", "error");
            setIsLoading(false);
          }
        })
        .catch((err) => {
          console.log('err: ', err);
          setIsLoading(false);
        });
    }
  };
  // JSX
  return (
    <RegistrationLayout>
      <RegisterLayoutBody
        title="Register"
        description="<p>
							Welcome to <strong> Animal Planet! </strong>Please Enter your
							Details.
						</p>"
        formData={FORMDATA.registerFields}
        buttonTitle={"Register Now!"}
        action={loginAction}
        btnIntuit={"Sign in with Intuit"}
        btnXero={"Sign in with Xero"}
        onSubmit={onSubmit}
        isLoading={isLoading}
        isIntuitLoading={isIntuitLoading}
        isXeroLoading={isXeroLoading}
        accountText={"Already have an account?"}
        accountUrl={"Login Now!"}
        setPhoneNumber={setPhoneNumber}
        phoneNumber={phoneNumber}
        setPhoneError={setPhoneError}
        phoneError={phoneError}
        setIsPasswordValidate={setIsPasswordValidate}
      ></RegisterLayoutBody>
    </RegistrationLayout>
  );
};

export default Register;
