
export const CssSpriteNames = {
    QUICKBOOKS: "QuickbooksOnline",
    QUICKBOOKSDESKTOP: "QuickbooksDesktop",
    XERO: "Xero",
    ZOHOBOOK: "ZohoBook",
    CLEARBOOK: "ClearBook",
    FRESHBOOKS: "FreshBooks",
    WAVEACCOUNT: "WaveAccount",
    SAGEINTACCT: "SageIntacct",
    SAP: "SAP",
    BUSINESSCENTRAL: "BusinessCentral",
    ODOO: "Odoo",
    ACUMATICA: "Acumatica",
    BRIGHTPEARL: "BrightPearl",
    MSDYNAMIC365: "MsDynamic",
    SHOPIFY: "Shopify",
    WOOCOMMERCE: "WooCommerce",
    MAGENTO: "Magento",
    BIGCOMMERCE: "BigCommerce",
    SHOPWARE: "ShopWare",
};
