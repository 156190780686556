import { Sidebar } from "components/Global";
import { FORMDATA } from "constants/Data";
import styles from "./index.module.scss";
import { FC } from "react";
import { SettingsLayoutProps } from "./types";
import { useSelector } from "react-redux";

// Settings page layout component
const SettingsLayout: FC<SettingsLayoutProps> = (props) => {
  // Initialize variables and props
  const { settingsMenuItems } = FORMDATA;
  const { children, onSideBarChange, selectedSidebar } = props;

  // Retrieve user profile and permissions
  const selectedCompany = useSelector(
    (state: any) => state?.userProfile?.data?.companies
  );

  // Extract permissions for the selected company
  const permissionsForSelectedCompany = selectedCompany?.map((data: any) => {
    if (data.companyId === localStorage.getItem("companyId")) {
      return data.role.permissions;
    }
  });

  // Initialize an array to store result permissions
  const resultArray: any = ["Configurations"];

  // Process permissions
  if (permissionsForSelectedCompany) {
    permissionsForSelectedCompany[0]?.map((item: any) => {
      if (item.view) {
        resultArray.push(item.permissionName);
      }
    });
  }
  console.log(permissionsForSelectedCompany);
  console.log(resultArray, settingsMenuItems);

  // Filter the menu items based on permissions
  const filteredMenuItems = settingsMenuItems.filter((item) =>
    resultArray.includes(item.label)
  );
  console.log(filteredMenuItems)
  // JSX
  return (
    <div className={styles["settings-layout"]}>
      <div className={styles["settings-layout__wrapper"]}>
        <Sidebar
          items={filteredMenuItems}
          isGetSupportButton={true}
          handleSidebar={onSideBarChange}
          selectedSidebar={selectedSidebar as string}
        />
        <div className={styles["settings-layout__body"]}>{children}</div>
      </div>
    </div>
  );
};

export default SettingsLayout;
