import React, { FC, useEffect } from "react";
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import DynamicCategoryTable from "components/Global/DynamicCategoryTable";
import { UserProfileInterface } from 'interfaces/user.interface';
import { checkPermission } from 'utils/utils';
import { integrationsAccountCards } from "utils/staticObject";

const AccountingTable: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Fetch permissions for the selected company from Redux state
  const selectedCompanyPermission = useSelector((state: UserProfileInterface) => state?.userProfile?.data?.companies[0]?.role?.permissions);
  
  // Determine if 'view' permission is granted for 'Integrations'
  const hasViewPermission = checkPermission(selectedCompanyPermission, {
    permissionName: 'Integrations',
    permission: ['view'],
  });

  useEffect(() => {
    // Check if the current location starts with '/settings/integrations',
    // the selected company has permissions, and 'view' permission is not granted
    if (location.pathname.startsWith('/settings/integrations') && selectedCompanyPermission?.length && !hasViewPermission) {
      // Redirect to the home page if view permission is not granted for Integrations
      navigate('/');
    }
  }, [location, selectedCompanyPermission?.length, hasViewPermission]);

  return (
    // Render the DynamicCategoryTable component with the provided data source and type
    <DynamicCategoryTable
      dataSource={integrationsAccountCards}
      type={integrationsAccountCards[0]?.type}
    />
  );
};

export default AccountingTable;
