import { useEffect } from "react";
import UnifiedIntegration from "../../components/unifiedIntegration"
import { getCurrentConnection } from "redux/action/saveConnectionAction";
import { useDispatch } from "react-redux";
import { AppDispatch } from "redux/store";

const UnifiedIntegrationComponent = () => {
    const dispatch = useDispatch<AppDispatch>();
    useEffect(() => {
        const fetchData = async () => {
            const data = {}
            await dispatch(getCurrentConnection(data));
        }
        fetchData();
    }, [])
    return (
        <div><UnifiedIntegration /></div>
    )
}

export default UnifiedIntegrationComponent;