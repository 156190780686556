/* eslint-disable react-hooks/rules-of-hooks */
import DynamicCategoryTable from "components/Global/DynamicCategoryTable";
import { UserProfileInterface } from 'interfaces/user.interface';
import { FC, useEffect } from "react";
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { integrationsEcommerceCards } from "utils/staticObject";
import { checkPermission } from 'utils/utils';
const EcommerceTable: FC = () => {

  // Get the current location
  const location = useLocation();
  // Initialize navigation function
  const navigate = useNavigate();

  // Get permissions from Redux state
  const selectedCompanyPermission = useSelector((state: UserProfileInterface) =>
    state?.userProfile?.data?.companies[0]?.role?.permissions
  );

 // Check if the user has view permission for Integrations
 const hasViewPermission = checkPermission(selectedCompanyPermission, {
  permissionName: 'Integrations',
  permission: ['view'],
});

  useEffect(() => {
     // Check if the user is on the Integrations page and doesn't have view permission
    if (location.pathname.startsWith('/settings/integrations') && selectedCompanyPermission?.length) {
      if (!hasViewPermission) {
        navigate('/')
      }
    }

  }, [location, selectedCompanyPermission?.length]);

  return <DynamicCategoryTable dataSource={integrationsEcommerceCards} type={integrationsEcommerceCards[0].type}/>;
};

export default EcommerceTable;
