import { TableColumnsType } from "antd";
import { DataType } from "components/AccountExpert";
import {
  CompanySvg,
  EmailSvg,
  IntegrationsSvg,
  PasswordSvg,
  PhoneSvg,
  RoleSvg,
  SubscriptionSvg,
  UserSvg,
  UsersSvg,
  StoreSvg,
  UrlSvg,
  ConfigurationsSvg
} from "utils/svgs";

const phoneNumberValidator = (_: any, value: any) => {
  // Modify this regex pattern to match the format of phone numbers you want to validate
  const phoneRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
  if (!value || value.match(phoneRegex)) {
    return Promise.resolve();
  }

  return Promise.reject(new Error("Please enter a valid phone number"));
};

export const FORMDATA = {
  loginFields: [
    {
      title: "Email Address",
      id: "email",
      type: "text",
      name: "email",
      svg: <EmailSvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your email address",
          validateTrigger: "onChange",
        },
        {
          type: "email",
          message: "Please enter valid e-mail",
          validateTrigger: "onChange",
        },
      ],
    },
    {
      title: "Password",
      id: "password",
      type: "password",
      name: "password",
      svg: <PasswordSvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your password",
          validateTrigger: "onChange",
        },
      ],
    },
  ],
  registerFields: [
    {
      title: "First Name",
      id: "firstName",
      type: "text",
      name: "firstName",
      svg: <UserSvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your first name",
          validateTrigger: "onChange",
        },
      ],
    },
    {
      title: "Last Name",
      id: "lastName",
      type: "text",
      name: "lastName",
      svg: <UserSvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your last name",
          validateTrigger: "onChange",
        },
      ],
    },
    {
      title: "Company Name",
      id: "companyName",
      type: "text",
      name: "companyName",
      svg: <CompanySvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your company name",
          validateTrigger: "onChange",
        },
      ],
    },
    {
      title: "Email Address",
      id: "email",
      type: "text",
      name: "email",
      svg: <EmailSvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your email address",
          validateTrigger: "onChange",
        },
        {
          type: "email",
          message: "Please enter valid e-mail",
          validateTrigger: "onChange",
        },
      ],
    },

    {
      title: "Password",
      id: "password",
      type: "password",
      name: "password",
      svg: <PasswordSvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please Enter your Password ",
          validationTrigger: "onBlur",
        },
        ({ getFieldValue }: any) => ({
          validator() {
            const re =
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
            if (getFieldValue("password") !== undefined) {
              if (re.test(getFieldValue("password"))) {
                return Promise.resolve();
              } else {
                return Promise.reject(
                  new Error(
                    "Please enter a password that is 8 characters long and contains at least 1 digit, 1 lowercase letter, and 1 uppercase letter"
                  )
                );
              }
            }
            return Promise.reject();
          },
          validateTrigger: "onSubmit",
        }),
      ],
    },
    {
      title: "Re Enter Password",
      id: "confirmPassword",
      type: "password",
      name: "confirmPassword",
      svg: <PasswordSvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please Re Enter your Password ",
          validationTrigger: "onBlur",
        },
        ({ getFieldValue }: any) => ({
          validator(_: any, value: any) {
            if (!value || getFieldValue("password") === value) {
              return Promise.resolve();
            }
            return Promise.reject(new Error("Passwords do not match!"));
          },
          validateTrigger: "onSubmit",
        }),
      ],
    },
    {
      title: "Phone Number",
      id: "phone",
      type: "text",
      name: "phone",
      svg: <PhoneSvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please input your phone number!",
          validationTrigger: "onBlur",
        },
        ({ getFieldValue }: any) => ({
          validator() {
            const re = /^(\+\d{1,3}[- ]?)?\d{10}$/;
            if (getFieldValue("phone") !== undefined) {
              if (re.test(getFieldValue("phone"))) {
                return Promise.resolve();
              } else {
                return Promise.reject(new Error("Please enter phone number"));
              }
            }
            return Promise.reject();
          },
          validateTrigger: "onSubmit",
        }),
      ],
    },
  ],
  resetPassword: [
    {
      title: "New Password",
      id: "password",
      type: "password",
      name: "password",
      svg: <PasswordSvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your password",
          validateTrigger: "onChange",
        },
      ],
    },
    {
      title: "Confirm Password",
      id: "confirmPassword",
      type: "password",
      name: "confirmPassword",
      svg: <PasswordSvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your password again",
          validationTrigger: "onChange",
        },
        ({ getFieldValue }: any) => ({
          validator(_: any, value: any) {
            if (!value || getFieldValue("password") === value) {
              return Promise.resolve();
            }
            return Promise.reject(new Error("Passwords do not match"));
          },
          validateTrigger: "onChange",
        }),
      ],
    },
  ],
  forgotPassword: [
    {
      title: "Email Address",
      id: "email",
      type: "text",
      name: "email",
      svg: <PasswordSvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your email address",
          validateTrigger: "onChange",
        },
        {
          type: "email",
          message: "Please enter valid e-mail",
          validateTrigger: "onChange",
        },
      ],
    },
  ],
  settingsMenuItems: [
    {
      key: "users",
      icon: <UsersSvg />,
      label: "Users",
    },
    {
      key: "roles",
      icon: <RoleSvg />,
      label: "Roles",
    },

    {
      key: "integrations",
      icon: <IntegrationsSvg />,
      label: "Integrations",
    },
    {
      key: "UnifiedIntegration",
      icon: <IntegrationsSvg />,
      label: "Unified Integration",
    },
    {
      key: "subscription",
      icon: <SubscriptionSvg />,
      label: "Subscriptions",
    },
    {
      key: "configurations",
      icon: <ConfigurationsSvg />,
      label: "Configurations",
    },
  ],
  newIntegrationMenuItems: [
    {
      key: "connectedApps",
      label: "Connected Apps",
    },
    {
      key: "accounting",
      label: "Accounting",
    },
    {
      key: "crm",
      label: "CRM",
    },
    {
      key: "ecommerce",
      label: "Ecommerce",
    },
    {
      key: "erp",
      label: "ERP",
    },
    {
      key: "marketplace",
      label: "Marketplace",
    },
    {
      key: "paymentGateway",
      label: "Payment Gateway",
    },
  ],
  pageMenuItems: [
    {
      key: "dashboard",
      label: "Dashboard",
    },
    {
      key: "AccountingExport",
      label: "Accounting Export",
    },
    {
      key: "roles",
      label: "Employee Costs",
    },
    {
      key: "timeActivity",
      label: "Time Activity",
    },
    {
      key: "journalEntries",
      label: "Journal Entries",
    },
    {
      key: "reports",
      label: "Reports",
    },
  ],
  addUserFields: [
    {
      title: "Full Name",
      id: "fullName",
      type: "text",
      name: "fullName",
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your full name",
          validateTrigger: "onChange",
        },
        {
          max: 30,
          message: "Full name length must be less than 30 characters",
          validateTrigger: "onChange",
        },
      ],
    },
    {
      title: "Select Role",
      id: "roleName",
      type: "text",
      name: "roleName",
      defaultValue: "",
      placeholder: "Enter role name",
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please select role",
          validateTrigger: "onChange",
        },
      ],
    },
    {
      title: "Email Address",
      id: "email",
      type: "text",
      name: "email",
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter email address",
          validateTrigger: "onChange",
        },
        {
          type: "email",
          message: "Please enter valid email address",
          validateTrigger: "onChange",
        },
      ],
    },
    {
      title: "Phone Number",
      id: "phone",
      type: "text",
      name: "phone",
      placeHolder: "",
      required: true,
      rules: [{ validator: phoneNumberValidator, validateTrigger: "onChange" }],
    },
  ],
  addRoleFields: [
    {
      title: "Role Name",
      id: "roleName",
      type: "text",
      name: "roleName",
      defaultValue: "",
      placeholder: "Enter role name",
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter role name",
          validateTrigger: "onChange",
        },
        {
          max: 50,
          message: "Role name length must be less than 50 characters",
          validateTrigger: "onChange",
        },
      ],
    },
    {
      title: "Description",
      id: "roleDescription",
      type: "textArea",
      name: "roleDescription",
      defaultValue: "",
      placeholder: "Enter role description",
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter role description",
          validateTrigger: "onSubmit",
        },
        {
          max: 200,
          message: "Role description length must be less than 200 characters",
          validateTrigger: "onChange",
        },
      ],
    },
  ],
  sageIntegrationFields: [
    {
      title: "Sender Id",
      id: "senderId",
      type: "text",
      name: "senderId",
      svg: <UserSvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your senderId",
          validateTrigger: "onChange",
        },
      ],
    },
    {
      title: "Sender Password",
      id: "senderPassword",
      type: "password",
      name: "senderPassword",
      svg: <PasswordSvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your Sender Password",
          validateTrigger: "onChange",
        },
      ],
    },
    {
      title: "User Id",
      id: "userId",
      type: "text",
      name: "userId",
      svg: <UserSvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your userId",
          validateTrigger: "onChange",
        },
      ],
    },
    {
      title: "User Password",
      id: "userPassword",
      type: "password",
      name: "userPassword",
      svg: <PasswordSvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your User Password",
          validateTrigger: "onChange",
        },
      ],
    },
    {
      title: "Company Name",
      id: "Company Name",
      type: "text",
      name: "companyName",
      svg: <CompanySvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your Company Name",
          validateTrigger: "onChange",
        },
      ],
    },
    {
      title: "Company Id",
      id: "companyId",
      type: "text",
      name: "companyId",
      svg: <CompanySvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your companyId",
          validateTrigger: "onChange",
        },
      ],
    },
  ],
  wooIntegrationFields: [
    {
      title: "Base Url",
      id: "baseUrl",
      type: "text",
      name: "baseUrl",
      svg: <UrlSvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your Base URL",
          validateTrigger: "onChange",
        },
      ],
    },
    {
      title: "Consumer Key",
      id: "Consumer Key",
      type: "password",
      name: "key",
      svg: <PasswordSvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your Consumer Key",
          validateTrigger: "onChange",
        },
      ],
    },
    {
      title: "Consumer Secret",
      id: "Consumer Secret",
      type: "password",
      name: "secret",
      svg: <PasswordSvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your Consumer Secret",
          validateTrigger: "onChange",
        },
      ],
    },
    {
      title: "Company Name",
      id: "Company Name",
      type: "text",
      name: "companyName",
      svg: <CompanySvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your Company Name",
          validateTrigger: "onChange",
        },
      ],
    },
    {
      title: "Company Id",
      id: "companyId",
      type: "text",
      name: "companyId",
      svg: <CompanySvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your companyId",
          validateTrigger: "onChange",
        },
      ],
    },
  ],

  clearBookIntegrationFields: [
    {
      title: "API Key",
      id: "API Key",
      type: "password",
      name: "secret",
      svg: <PasswordSvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your API Key",
          validateTrigger: "onChange",
        },
      ],
    },
    {
      title: "Company Name",
      id: "Company Name",
      type: "text",
      name: "companyName",
      svg: <CompanySvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your Company Name",
          validateTrigger: "onChange",
        },
      ],
    },
  ],
  shopifyIntegrationFields: [
    {
      title: "Store Name",
      id: "store",
      type: "shopifyStoretextField",
      name: "store",
      svg: <StoreSvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your Store Name",
          validateTrigger: "onChange",
        },
      ],
    },
  ],
  netsuiteIntegrationFields: [
    {
      title: "Consumer Key",
      id: "consumerKey",
      type: "text",
      name: "consumerKey",
      svg: <PasswordSvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your Consumer Key",
          validateTrigger: "onChange",
        },
      ],
    },
    {
      title: "Consumer Secret",
      id: "consumerSecret",
      type: "text",
      name: "consumerSecret",
      svg: <PasswordSvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your Consumer Secret",
          validateTrigger: "onChange",
        },
      ],
    },
    {
      title: "Access Token",
      id: "accessToken",
      type: "text",
      name: "accessToken",
      svg: <PasswordSvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your Access Token",
          validateTrigger: "onChange",
        },
      ],
    },
    {
      title: "Token Secret",
      id: "tokenSecret",
      type: "text",
      name: "tokenSecret",
      svg: <PasswordSvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your Token Secret",
          validateTrigger: "onChange",
        },
      ],
    },
    {
      title: "Account Id",
      id: "accountId",
      type: "text",
      name: "accountId",
      svg: <UserSvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your Account Id",
          validateTrigger: "onChange",
        },
      ],
    },
  ],
  qbdIntegrationFields: [
    {
      title: "User Name",
      id: "username",
      type: "qbd",
      name: "username",
      svg: <UserSvg />,
      placeHolder: "",
    },
    {
      title: "Password",
      id: "password",
      type: "password",
      name: "password",
      svg: <PasswordSvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your password",
          validateTrigger: "onChange",
        },
        {
          min: 6,
          message: "Password must be at least 6 characters long"
        }
      ],
    },
  ],
  authorizeNetIntegrationFields: [
    {
      title: "API login ID",
      id: "apiloginid",
      type: "text",
      name: "apiLoginId",
      svg: <UserSvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your API Login ID",
          validateTrigger: "onChange",
        },
      ],
    },
    {
      title: "Transaction Key",
      id: "transactionkey",
      type: "text",
      name: "transactionKey",
      svg: <PasswordSvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your transaction Key",
          validateTrigger: "onChange",
        },
      ],
    },
    {
      title: "Company Name",
      id: "companyName",
      type: "text",
      name: "companyName",
      svg: <CompanySvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your companyName",
          validateTrigger: "onChange",
        },
      ],
    },
    // {
    //   title: "Company ID",
    //   id: "companyID",
    //   type: "text",
    //   name: "companyID",
    //   svg: "",
    //   placeHolder: "",
    //   required: true,
    //   rules: [
    //     {
    //       required: true,
    //       message: "Please enter your companyID",
    //       validateTrigger: "onChange",
    //     },
    //   ],
    // },
  ],
  magentoIntegrationFields: [
    {
      title: "Store url",
      id: "magentoStoreUrl",
      type: "magentoStoretextField",
      name: "magentostore",
      svg: <StoreSvg />,
      placeHolder: "Please enter store name",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your Store Name",
          validateTrigger: "onChange",
        },
      ],
    },
    {
      title: "User Name",
      id: "username",
      type: "text",
      name: "username",
      svg: <UserSvg />,
      placeHolder: "Please enter user name",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your user name",
          validateTrigger: "onChange",
        },
      ],
    },
    {
      title: "Password",
      id: "password",
      type: "password",
      name: "password",
      svg: <PasswordSvg />,
      placeHolder: "Please enter password",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your password",
          validateTrigger: "onChange",
        }
      ],
    },

  ],
};

export const userColumns = [
  {
    title: "Organization Name",
    dataIndex: "name",
    key: "name",
    sorter: (a: any, b: any) => {
      return a.name.length - b.name.length;
    },
    sortDirections: ["descend"],
  },
  {
    title: "Email Address",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Phone number",
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: "Role",
    dataIndex: "role",
    key: "role",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
  },
];

export const roleDataSource: any = [];
roleDataSource.push({
  name: `Admin`,
  description: `Description for role`,
  permissions: `Permission Details`,
  status: true,
  action: `some action`,
});

for (let index = 0; index < 15; index++) {
  roleDataSource.push({
    name: `Role ${index}`,
    description: `Description for role ${index}`,
    permissions: `Permission Details`,
    status: index % 2 == 0 ? true : false,
    action: `some action`,
  });
}

export const roleColumns = [
  {
    title: "Role Name",
    dataIndex: "name",
    key: "name",
    sorter: (a: any, b: any) => {
      return a.name.length - b.name.length;
    },
    sortDirections: ["descend"],
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "Permissions",
    dataIndex: "permissions",
    key: "permissions",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
  },
];
export const AccountExoport: TableColumnsType<DataType> = [
  {
    title: "vendorName",
    dataIndex: "vendorId",
    key: "vendorId",
    sorter: (a: any, b: any) => {
      return a.name.length - b.name.length;
    },
    sortDirections: ["descend"],
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
  },
  {
    title: "type",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "modifiedAt",
    dataIndex: "updatedAt",
    key: "updatedAt",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
  },
];

export const PermissionDataSource: any = [
  {
    moduleName: "Admin",
    isParentModule: true,
    all: false,
    view: false,
    edit: false,
    delete: false,
  },
  {
    moduleName: "Dashboard",
    isParentModule: false,
    all: true,
    view: false,
    edit: false,
    delete: false,
  },
  {
    moduleName: "Accounting Export",
    isParentModule: false,
    all: true,
    view: false,
    edit: false,
    delete: false,
  },
  {
    moduleName: "Employee Cost",
    isParentModule: false,
    all: false,
    view: true,
    edit: true,
    delete: false,
  },
  {
    moduleName: "Cost Allocation",
    isParentModule: false,
    all: false,
    view: false,
    edit: false,
    delete: false,
  },
  {
    moduleName: "Journal Entries",
    isParentModule: false,
    all: false,
    view: true,
    edit: true,
    delete: false,
  },
  {
    moduleName: "Time Activities",
    isParentModule: true,
    all: true,
    view: false,
    edit: false,
    delete: true,
  },
  {
    moduleName: "TimeLogs",
    isParentModule: false,
    all: false,
    view: false,
    edit: false,
    delete: false,
  },
  {
    moduleName: "TimeSheets",
    isParentModule: false,
    all: false,
    view: true,
    edit: true,
    delete: true,
  },
  {
    moduleName: "Settings",
    isParentModule: true,
    all: false,
    view: false,
    edit: false,
    delete: false,
  },
  {
    moduleName: "Roles",
    isParentModule: false,
    all: false,
    view: false,
    edit: false,
    delete: false,
  },
  {
    moduleName: "Users",
    isParentModule: false,
    all: false,
    view: false,
    edit: false,
    delete: false,
  },
  {
    moduleName: "Integrations",
    isParentModule: false,
    all: false,
    view: false,
    edit: false,
    delete: false,
  },
  {
    moduleName: "Configurations",
    isParentModule: false,
    all: false,
    view: false,
    edit: false,
    delete: false,
  },
  {
    moduleName: "Subscriptions",
    isParentModule: false,
    all: false,
    view: false,
    edit: false,
    delete: false,
  },
  {
    moduleName: "Custom Rules",
    isParentModule: false,
    all: false,
    view: false,
    edit: false,
    delete: false,
  },
  {
    moduleName: "Reports",
    isParentModule: true,
    all: false,
    view: false,
    edit: false,
    delete: false,
  },
  {
    moduleName: "Time Summary",
    isParentModule: false,
    all: false,
    view: false,
    edit: false,
    delete: false,
  },
  {
    moduleName: "Payroll Summary",
    isParentModule: false,
    all: false,
    view: false,
    edit: false,
    delete: false,
  },
  {
    moduleName: "Customer Overview",
    isParentModule: false,
    all: false,
    view: false,
    edit: false,
    delete: false,
  },
];

export const UserProfileData = [
  {
    title: "First Name",
    id: "firstName",
    type: "text",
    name: "firstName",
    defaultValue: "",
    disabled: false,
    errorMessage: "Please enter your first name",
    required: true,
    rules: [
      {
        required: true,
        message: "Please enter your first name",
        validateTrigger: "onChange",
      },
      {
        max: 30,
        message: "First name length must be less than 30 characters",
        validateTrigger: "onChange",
      },
    ],
  },
  {
    title: "Last Name",
    id: "lastName",
    type: "text",
    name: "lastName",
    defaultValue: "",
    disabled: false,
    errorMessage: "Please enter your last name",
    required: true,
    rules: [
      {
        required: true,
        message: "Please enter your last name",
        validateTrigger: "onChange",
      },
      {
        max: 30,
        message: "First name length must be less than 30 characters",
        validateTrigger: "onChange",
      },
    ],
  },
  {
    title: "Email Address",
    id: "email",
    type: "text",
    name: "email",
    defaultValue: "",
    disabled: true,
    errorMessage: "Please enter your email",
    required: false,
    rules: [],
  },
  {
    title: "Phone Number",
    id: "phone",
    type: "number",
    name: "phone",
    defaultValue: "",
    disabled: false,
    errorMessage: "Please enter your number",
    required: true,
    rules: [
      {
        required: true,
        message: "Please enter your number",
        validateTrigger: "onChange",
      },
      { validator: phoneNumberValidator, validateTrigger: "onChange" },
    ],
  },
];

export const permissionObject = [
  // {
  // 	name: 'Admin',
  // 	items: [1, 2, 3, 4],
  // },
  // {
  // 	name: 'Time Activity',
  // 	items: [5, 6],
  // },
  {
    name: "Settings",
    items: [1, 7, 8, 9, 11],
  },
  // {
  // 	name: 'Reports',
  // 	items: [11, 12, 13, 14],
  // },
];

export const integrationDataSource: any = [
  {
    key: "1",
    Accounting_Software: {
      connection: "QBO",
      company: "sandbox",
    },
    Ecommerce_Software: {
      connection: "Magento",
      company: "Magento 1",
    },
    Status: true,
    tags: ["nice", "developer"],
  },
  {
    key: "2",
    Accounting_Software: {
      connection: "XERO",
      company: "sandbox",
    },
    Ecommerce_Software: {
      connection: "Shopify",
      company: "shopify 1",
    },
    Status: false,
    tags: ["nice", "developer"],
  },
  {
    key: "3",
    Accounting_Software: {
      connection: "ZOHO",
      company: "ZohoBooks 1",
    },
    Ecommerce_Software: {
      connection: "ShopWare",
      company: "ShopWare 1",
    },
    Status: true,
    tags: ["nice", "developer"],
  },
];

export const TechnicalSupportEmailForm = [
  {
    title: "Subject",
    id: "emailSubject",
    type: "text",
    name: "emailSubject",
    defaultValue: "",
    disabled: false,
    errorMessage: "Please enter subject",
    required: true,
    rules: [
      {
        required: true,
        message: "Please enter an email subject",
        validateTrigger: "onChange",
      },
      {
        min: 3,
        message: "Email subject should be at least 3 characters long",
        validateTrigger: "onChange",
      },
    ],
  },
  {
    title: "How can we help you today?",
    id: "emailBody",
    type: "textarea",
    name: "emailBody",
    defaultValue: "",
    disabled: false,
    errorMessage: "Please describe the issue",
    required: true,
    rules: [
      {
        required: true,
        message: "Please enter details about your issue",
        validateTrigger: "onChange",
      },
      {
        max: 500,
        message: "Content should not exceed 500 characters",
        validateTrigger: "onChange",
      },
    ],
  },
  {
    title: "Attachments",
    id: "emailAttachment",
    type: "fileUpload",
    name: "emailAttachment",
    defaultValue: "",
    disabled: false,
    errorMessage: "Add Attachment if any",
    required: false,
    rules: [],
  },
  {
    title: "Phone Number",
    id: "phone",
    type: "phone",
    name: "phone",
    placeHolder: "",
    required: true,
    rules: [{ validator: phoneNumberValidator, validateTrigger: "onChange" }],
  },
  {
    title: "How critical is your request ?",
    id: "issueIntensity",
    type: "dropdown",
    name: "issueIntensity",
    defaultValue: "",
    disabled: false,
    errorMessage: "Please select the inetnsity of your issue",
    required: true,
    rules: [
      {
        required: true,
        message: "Please select the intensity of your issue",
        validateTrigger: "onChange",
      },
    ],
  },
];

export const IntegrationUrl = [
  {
    SageIntacct:
      "https://www.intacct.com/ia/docs/en_US/help_action/Administration/Users/web-services-only-users.htm?cshid=Web_services_users#AddaWebServicesuser",
  },
  {
    WooCommerce:
      "https://woocommerce.github.io/woocommerce-rest-api-docs/?javascript#index",
  },
  {
    Stripe: "https://stripe.com/docs/api/errors/handling?lang=node",
  },
  {
    Netsuite:
      "https://drive.google.com/file/d/10l3i2S4H80OTs7JQn-jpVwu5zHcrGF5v/view?usp=drive_link",
  },
  {
    ClearBooks: "https://www.clearbooks.co.uk/support/api/docs/soap/",
  },
  {
    "Authorize.net":
      "https://support.authorize.net/knowledgebase/Knowledgearticle/?code=000001560",
  },
];
