import React, { FC, useState } from "react";
import {
  Button,
  Divider,
  Form,
  Input,
  Modal,
  Select,
  Upload,
  Tag,
  Typography,
} from "antd";
import {
  InfoCircleOutlined,
  PhoneFilled,
  UploadOutlined,
} from "@ant-design/icons";
import styles from "./index.module.scss";
import { EmailSupportModalProps } from "./types";
import TextArea from "antd/es/input/TextArea";
import { CloseSvg } from "utils/svgs";
import phoneNumberFormatter from "helper/phoneNumberFormatter";
import { toastText } from "utils/utils";

const { Text } = Typography;

const EmailSupportModal: FC<EmailSupportModalProps> = (props) => {
  const {
    handleCancel,
    handleOk,
    isModalOpen,
    title,
    formData,
    setPhoneNumber,
    // phoneNumber,
  } = props;
  const [form] = Form.useForm();
  const [isSaving] = useState(false);
  const [phoneError, setPhoneError] = useState("");
  const [num, setNum] = useState("");

  const validatePhoneNumber = () => {
    const re = /^(\+\d{1,3}[- ]?)?\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
    return re.test(num.replace(/\s/g, ""));
  };

  const handleFormReset = () => {
    form.resetFields();
  };

  const handleFormSubmit = async () => {
    if (!validatePhoneNumber()) {
      setPhoneError("Please enter a valid phone number");
      return;
    }

    setPhoneError("");
    handleFormReset();
    handleCancel();
    setPhoneNumber("");
    setNum("");
    toastText("Response submitted successfully!", "success");
  };

  const onFinishFailed = () => {
    if (num === "") {
      setPhoneError("Please enter a phone number");
    } else if (!validatePhoneNumber()) {
      setPhoneError("Please enter a valid phone number");
      return;
    } else {
      setPhoneError("");
    }
  };

  const phoneChangeHandler = (e: any) => {
    const value = e.target.value;
    if (value === "") {
      setPhoneError("Please enter a phone number");
    } else if (value.length !== 14) {
      setNum(phoneNumberFormatter(value));
      setPhoneError("Please enter a valid phone number");
    } else {
      setNum(phoneNumberFormatter(value));
      setPhoneError("");
    }
  };
  return (
    <Modal
      className={`${styles["EmailSupport-model_width"]} EmailSupport-model_width `}
      title={
        <div className={styles["EmailSupport-model_title"]}>
          <h3>{title}</h3>
        </div>
      }
      open={isModalOpen}
      onOk={handleOk}
      onCancel={() => {
        handleCancel();
        handleFormReset();
        setPhoneError("");
        setNum("");
      }}
      centered={true}
      width={1000}
      closeIcon={<CloseSvg />}
      footer={null}
    >
      <Divider
        type="horizontal"
        style={{ border: "1.5px solid #CCCCCC", margin: "10px 0px" }}
      />
      <Tag color="processing" className={styles["tag-attribute"]}>
        <div className="EmailSupport--tag-space">
          <span>
            <InfoCircleOutlined />
            &nbsp;Responses will be sent via email to :-
            support@yourfintechapp.com
          </span>
        </div>
      </Tag>
      <div>
        <Form
          form={form}
          onFinish={handleFormSubmit}
          onFinishFailed={onFinishFailed}
        >
          {formData?.map((fieldData: any, index: any) => (
            <div className={styles["input-icon"]} key={index}>
              <div className={styles["input-icon__title"]}>
                {fieldData.svg && (
                  <div className={styles["input-icon__title--svg"]}>
                    {fieldData.svg}
                  </div>
                )}
                <label className={styles["input-icon__title--label"]}>
                  {fieldData.title}{" "}
                  {fieldData?.required && (
                    <span className="required-color">*</span>
                  )}
                </label>
              </div>
              <div className={styles["input-icon__form"]}>
                <Form.Item name={fieldData.name} rules={fieldData.rules}>
                  {fieldData.type === "text" && (
                    <Input
                      placeholder={fieldData.placeHolder}
                      size="large"
                      className={styles["input-icon__form--input"]}
                      type={fieldData.type}
                    />
                  )}
                  {fieldData.name === "phone" && (
                    <>
                      <Input
                        addonBefore={<PhoneFilled />}
                        placeholder={fieldData.placeHolder}
                        size="large"
                        onChange={phoneChangeHandler}
                        value={num}
                        maxLength={14}
                      />
                      <span className={styles["phoneError"]}>{phoneError}</span>
                    </>
                  )}
                  {fieldData.type === "textarea" && (
                    <TextArea
                      placeholder={fieldData.placeHolder}
                      size="large"
                      style={{ height: 80, resize: "none" }}
                      className={styles["input-icon__form--input"]}
                    />
                  )}
                  {fieldData.type === "fileUpload" && (
                    <Upload>
                      <Button icon={<UploadOutlined />}>Choose File</Button>
                    </Upload>
                  )}
                  {fieldData.type === "dropdown" && (
                    <Select
                      defaultValue="Choose request"
                      size="large"
                      options={[
                        { value: "Just FYI", label: "Just FYI" },
                        {
                          value: "Nothing urgent can wait",
                          label: "Nothing urgent can wait",
                        },
                        {
                          value: "I am stuck, need assistance",
                          label: "I am stuck, need assistance",
                        },
                      ]}
                    />
                  )}
                </Form.Item>
              </div>
            </div>
          ))}
          <Divider
            type="horizontal"
            style={{ border: "1.5px solid #CCCCCC", margin: "10px 0px" }}
          />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              key={"wrapper"}
              className={styles["EmailSupport-model__button"]}
            >
              <button
                style={{ padding: "0px", placeItems: "center", height: "45px" }}
                type="submit"
                className={`${styles["EmailSupport-model__button--save"]} ${styles["EmailSupport-model__button--btn"]}`}
              >
                {isSaving ? (
                  <img
                    src={`${process.env.REACT_APP_IMAGES_BASE_URL}/assets/gifs/loading-black.gif`}
                    height={40}
                    crossOrigin={
                      process.env.REACT_APP_ENV === "local"
                        ? undefined
                        : "anonymous"
                    }
                  />
                ) : (
                  "Save"
                )}
              </button>
              <button
                type="button"
                onClick={() => {
                  handleCancel();
                  handleFormReset();
                  setPhoneError("");
                  setNum("");
                }}
                className={`${styles["EmailSupport-model__button--cancel"]} ${styles["EmailSupport-model__button--btn"]}`}
              >
                Cancel
              </button>
            </div>

            <div>
              <Text>
                <strong>Fintech App Support :</strong> +1 (646) 760-6450 <br />
                <span>Mon - Fri | 9:00 AM - 7:00 PM | Toll Free</span>
              </Text>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default EmailSupportModal;
