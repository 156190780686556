import { Button, Layout, Menu } from "antd";
import { FC, useState } from "react";
import styles from "./index.module.scss";
import "./index.scss";
import { SidebarProps } from "./types";
import EmailSupportModal from "components/GetSupport";
import { TechnicalSupportEmailForm } from "constants/Data";

// Global sidebar
const Sidebar: FC<SidebarProps> = (props) => {
  // Initialize variables and state
  const { Sider } = Layout;
  const { handleSidebar, items, isGetSupportButton, selectedSidebar } = props;

  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [formData, setFormData] = useState([{}]);
  const [isEmailSupportModalOpen, setisEmailSupportModalOpen] = useState(false);
  const [isLoading] = useState(false);

  // Handle cancel action for Email Support Modal
  const EmailSupportModalHandleCancel = () => {
    setisEmailSupportModalOpen(false);
  };

  // Handle Email Support Modal
  const EmailSupportModalHandle = () => {
    setisEmailSupportModalOpen(false);
  };

  // Show the Email Support Modal
  const showModal = () => {
    setisEmailSupportModalOpen(true);
  };


  // JSX
  return (
    <Sider
      style={{
        background: "#fff",
        maxHeight: "100%",
        height: "100%",
        overflow: "auto",
      }}
      className={styles.sidebar}
    >
      <div className={styles.sidebar__wrapper}>
        <Menu
          mode="inline"
          defaultSelectedKeys={[selectedSidebar]}
          selectedKeys={[selectedSidebar]}
          items={items}
          onClick={handleSidebar}
          className="menu-item "
          style={{
            background: "#fff",
            padding: 0,
          }}
        />
        {isGetSupportButton && (
          <div className={styles.sidebar__support}>
            <Button
              type="primary"
              ghost={true}
              className={styles["sidebar__support--button"]}
              onClick={() => {
                setFormData(TechnicalSupportEmailForm);
                showModal();
              }}
            >
              Get Support
            </Button>
          </div>
        )}
      </div>
      <EmailSupportModal
        handleCancel={EmailSupportModalHandleCancel}
        handleOk={EmailSupportModalHandle}
        isModalOpen={isEmailSupportModalOpen}
        formData={formData}
        isLoading={isLoading}
        title={"At your service"}
        setPhoneNumber={setPhoneNumber}
        phoneNumber={phoneNumber}
        setPhoneError={setPhoneError}
        phoneError={phoneError}
      />
    </Sider>
  );
};

export default Sidebar;
