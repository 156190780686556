import { createSlice } from "@reduxjs/toolkit";
import { getBillPaymentAction, deleteBillAction, postedBillAction, billPaymentSync } from "redux/action/billPaymentAction"
import { toastText } from "utils/utils";
const initialState: any = {
    data: null,
    isLoading: true,
    updateLoader: false,
    totalPages: 0,

    error: null,
};
const BillPaymentSlice = createSlice({
    name: "billPayment",
    initialState,
    reducers: {
        clearDeleteBill: (state) => {
            state.isLoading = false;
            state.isDelete = false;
            state.isDeleteError = false;
        }
    },
    extraReducers: (builder) => {
        // Fetch Profile
        builder.addCase(getBillPaymentAction.pending, (state) => {
            state.isLoading = true;

            state.error = null;
        });
        builder.addCase(getBillPaymentAction.fulfilled, (state, action) => {

            state.totalPages = action?.payload?.total;
            state.isLoading = false;
            state.data = action?.payload.data;

        });
        builder.addCase(getBillPaymentAction.rejected, (state, action: any) => {
            state.isLoading = false;
            state.error = action.payload;
            state.data = [];
            state.totalPages = 0;
        });
        builder.addCase(postedBillAction.pending, (state) => {
            state.isLoading = true;

            state.error = null;
        });
        builder.addCase(postedBillAction.fulfilled, (state, action) => {
            state.isLoading = false
            toastText(action?.payload?.message, "success");

        });
        builder.addCase(postedBillAction.rejected, (state, action: any) => {
            state.isLoading = false;
            state.error = action.payload;

            toastText(action?.payload?.error, "error");

        });


        builder.addCase(deleteBillAction.pending, (state) => {
            state.isLoading = true;
            state.isDelete = true;

            state.error = null;
        });
        builder.addCase(deleteBillAction.fulfilled, (state, action) => {

            state.data = state?.data?.filter(
                (item: any) => item?.id !== action?.payload?.id
            );
            state.count = state.count - 1;
            state.isLoading = false;
            console.log("aaaa", action?.payload)
            toastText(action?.payload?.message, "success");


        });
        builder.addCase(deleteBillAction.rejected, (state, action: any) => {


            state.error = action.payload;
            state.isLoading = false;
            toastText(action?.payload?.error, "error");



        });



        builder.addCase(billPaymentSync.pending, (state) => {
            state.isLoading = true;


            state.error = null;
        });
        builder.addCase(billPaymentSync.fulfilled, (state, action) => {


            state.isLoading = false;

            toastText(action?.payload?.message, "success");


        });
        builder.addCase(billPaymentSync.rejected, (state, action: any) => {


            state.error = action.payload;
            state.isLoading = false;
            toastText(action?.payload?.error, "error");



        });



    },
});
export default BillPaymentSlice
export const { clearDeleteBill } = BillPaymentSlice.actions;
