import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { apiCall } from '../api/api';
import { CssSpriteNames } from "../utils/staticObject";
import "../assets/css/index.css"



const CallbackComponent = ({ updateApiData }: any) => {
    const navigate = useNavigate()
    const activeIntegration: any = JSON.parse(localStorage.getItem("selectedIntegration") || '{}');

    const [isLoading, setIsLoading] = useState(false);
    const [isConnect, setIsConnect] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [queryParams] = useSearchParams();
    // const location = useLocation();
    // const navigate = useNavigate();


    // Define a function to handle API calls and error handling
    const handleApiCall = async (apiEndpoint: any, data: any) => {
        try {
            // Make an API request
            const res: any = await apiCall("post", apiEndpoint, data);

            if (res?.statusCode === 200) {
                const data = {
                    ...res.data,
                    connectionType: activeIntegration?.integrationName,
                    connectionId: res?.data?.id
                }
                updateApiData(data)
                localStorage.setItem("response", JSON.stringify(res?.data));
                setIsConnect(true)
            }



        } catch (error: any) {

            setError(true)

            setErrorMessage(error.message)
        }

    };

    useEffect(() => {
        const run = async () => {
            setIsLoading(true);


            // Check the current pathname to determine the callback logic
            if (activeIntegration?.integrationName === "FRESHBOOKS") {
                await handleApiCall("/fresh-books/callback", {
                    code: queryParams.get("code"),
                });
            } else if (activeIntegration?.integrationName === "QUICKBOOKS") {
                const url = window.location.href;
                await handleApiCall("/qbo/callback", {
                    url,
                    companyId: localStorage.getItem("t"),
                });
            } else if (activeIntegration?.integrationName === "ZOHOBOOK") {
                await handleApiCall("/zoho/callback", {
                    code: queryParams.get("code"),
                    companyId: localStorage.getItem("t"),
                });
            } else if (activeIntegration?.integrationName === "XERO") {
                const currentURL = window.location.href;
                const origin = window.location.origin;
                const url = currentURL.replace(origin, "");
                await handleApiCall("/xero/callback", {
                    url,
                    companyId: localStorage.getItem("t"),
                });
            } else if (activeIntegration?.integrationName === "WAVEACCOUNT") {
                await handleApiCall("/wave/callback", {
                    code: queryParams.get("code"),
                    companyId: localStorage.getItem('t')
                });
            }
            setIsLoading(false);
        };

        run();
    }, []);

    return (
        <div>
            {isLoading && (
                "loading"
            )}
            {isConnect && (
                <>
                    <div className="software-grid grid-container ">
                        <div className="grid-item-child connected">
                            <div className="check-circle">
                                <div className="check-mark"></div>
                            </div>
                            <div className={`grid-item  bg-${CssSpriteNames[
                                activeIntegration.integrationName as keyof typeof CssSpriteNames]
                                }`}>

                            </div>
                        </div>




                    </div>
                    <div className="click-button">
                        <button className='btn btn-primary' onClick={() => navigate("/AccountingExport")} > connected</button>
                    </div>
                </>
            )}
            {error && (
                <>
                    <div className="software-grid grid-container ">
                        <div className="grid-item-child error">

                            <div className={`grid-item  bg-${CssSpriteNames[
                                activeIntegration.integrationName as keyof typeof CssSpriteNames]
                                }`}>

                            </div>
                        </div>




                    </div>
                    <div className="click-button">
                        <p className="error-message"> {errorMessage}</p>
                    </div>
                </>
            )}

        </div>
    );
};

export default CallbackComponent;
