import { Header, Sidebar } from "components/Global";
import { Outlet, useNavigate } from "react-router-dom";
import { FORMDATA } from "constants/Data";
import styles from "./index.module.scss";
import { useSelector } from "react-redux";
import { useState } from "react";


const GlobalLayout = () => {
  // Initialize variables
  const navigate = useNavigate()
  const { pageMenuItems } = FORMDATA;
  const { isLoading } = useSelector((state: any) => state.userProfile);
  const [selectedSidebar, setSelectedSidebar] = useState<string>("dashboard");
  // Get the selected company and its permissions
  const selectedCompany = useSelector(
    (state: any) => state?.userProfile?.data?.companies
  );
  const selectedCompanyId = localStorage.getItem("companyId");
  const permissionForSelectedCompany = selectedCompany?.find(
    (data: any) => data.companyId === selectedCompanyId
  );

  // Create an array of permissions with 'view' access
  const resultArray =
    permissionForSelectedCompany?.role.permissions
      .filter((item: any) => item.view)
      .map((item: any) => item.permissionName) || [];
  console.log(permissionForSelectedCompany);

  // Filter menu items based on permissions
  const filteredMenuItems = pageMenuItems.filter((item) =>
    resultArray.includes(item.label)
  );


  // JSX
  return !isLoading ? (
    <div className={styles["global-layout"]}>
      <div className={styles["global-layout__wrapper"]}>
        <div className={styles["global-layout__header"]}>
          <Header />
        </div>
        <div className={styles["global-layout__body"]}>
          <div className={styles["global-layout__body--sidebar"]}>
            <Sidebar
              items={filteredMenuItems}
              isGetSupportButton={false}
              handleSidebar={(e) => {

                setSelectedSidebar(e.key);
                navigate(e.key)
              }}
              selectedSidebar={selectedSidebar}
            />
          </div>
          <div className={styles["global-layout__body--body"]}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default GlobalLayout;
