import { Space, Switch, Table, Typography } from "antd";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  editRoleActionTable,
  getRoleActionTable,
} from "redux/action/roleTableAction";
import { AppDispatch } from "redux/store";

import {
  DeleteActionSvg,
  EditActionSvg,
  PermissionDetailsAdminSvg,
  PermissionDetailsSvg,
  SortSvgBottom,
  SortSvgTop,
} from "utils/svgs";
import SearchAndFilter from "../SearchAndFilter/index";
import styles from "./index.module.scss";
import "./index.scss";
import { DynamicTableProps } from "./types";
import { defaultRoles, pageSizeOptionsPaging } from "utils/constant";
import { UserProfileInterface } from "interfaces/user.interface";

const { Text } = Typography;

const DynamicTable: FC<DynamicTableProps> = (props) => {
  const { Column } = Table;
  const {
    roleDataSource,
    performSearchHandler,
    performFilterHandler,
    searchValue,
    filterValue,
    showModal,
    openDrawerHandler,
    setDrawerInfoHandler,
    openPermissionsHandler,
    setEditSelectedRole,
    fetchRolePermissions,
    paginationChangeHandler,
    totalRecords,
    currentPage,
    performSortHandler,
    permissions,
    PageSize,
    modifyPageSize,
    sortDirection,
  } = props;

  const dispatch = useDispatch<AppDispatch>();

  // Handle edit data action
  const editDataHandler = (roleObject: any) => {
    openDrawerHandler();
    setDrawerInfoHandler("Edit Role");
    setEditSelectedRole({
      ...roleObject,
      roleName: roleObject?.name,
      roleDescription: roleObject?.description,
    });
  };

  // Handle delete data action
  const deleteDataHandler = (roleObject: any) => {
    setEditSelectedRole({
      ...roleObject,
      roleName: roleObject?.name,
      roleDescription: roleObject?.description,
    });
  };

  // Handle permissions action

  const permissionsHandler = () => {
    openPermissionsHandler();
    setDrawerInfoHandler("Permission Details");
  };

  // Handle status change
  const statusHandler = async (value: any, data: any) => {
    const finalData = {
      roleId: data?.id,
      status: value,
    };
    dispatch(editRoleActionTable(finalData)).then(async () => {
      let url = `page=${currentPage}&limit=${PageSize}&search=${searchValue}&sort=${
        sortDirection === "ascend" ? "asc" : "desc"
      }`;
      if (filterValue !== "all") {
        url += `&filter=${filterValue === "active"}`;
      }
      await dispatch(
        getRoleActionTable({
          url,
          isPagination: false,
        })
      );
    });
  };

  // For handle the table change click
  const tableChangeHandler = (d1: any, d2: any, data: any) => {
    performSortHandler && performSortHandler(data.order, d1.current);
  };

  const loggedInUserRoleId = useSelector(
    (state: UserProfileInterface) =>
      state?.userProfile?.data?.companies[0]?.role?.id
  );

  // JSX
  return (
    <div className={styles["dynamic-table"]}>
      <SearchAndFilter
        performSearchHandler={performSearchHandler}
        searchValue={searchValue}
        performFilterHandler={performFilterHandler}
        filterValue={filterValue}
        PageSize={PageSize}
      />
      <Table
        dataSource={roleDataSource}
        pagination={{
          total: totalRecords,
          current: currentPage,
          onChange: paginationChangeHandler,
          pageSize: PageSize,
          pageSizeOptions: pageSizeOptionsPaging,
          showSizeChanger: true,
          onShowSizeChange: modifyPageSize,
        }}
        // loading={isLoading}
        className="table-global"
        onChange={tableChangeHandler}
        scroll={{ y: "calc(70vh - 190px)", x: "63vh" }}
      >
        <Column
          sortIcon={(data) => {
            return data.sortOrder === "ascend" ? (
              <SortSvgTop />
            ) : (
              <SortSvgBottom />
            );
          }}
          showSorterTooltip={{ title: "" }}
          defaultSortOrder={sortDirection}
          sorter={() => {
            return null as any;
          }}
          title={() => {
            return <>Role Name</>;
          }}
          dataIndex="name"
          key="name"
          width={"15%"}
          sortDirections={["ascend", "descend", "ascend"]}
          className="bg-white"
        />
        <Column
          title="Description"
          dataIndex="description"
          key="description"
          width={"25%"}
          className="bg-white"
        />
        <Column
          title="Status"
          dataIndex="status"
          key="status"
          className="bg-white"
          width={"20%"}
          render={(value, rowData: any) => {
            return (
              <div className={styles["dynamic-table__status"]}>
                {rowData?.isAdmin || loggedInUserRoleId === rowData.id ? (
                  <>
                    <PermissionDetailsAdminSvg />
                    <Text className={styles["dynamic-table__status--admin"]}>
                      Enabled
                    </Text>
                  </>
                ) : (
                  <Switch
                    key={rowData.id}
                    disabled={!permissions?.edit}
                    checked={value}
                    onChange={(e) => statusHandler(e, rowData)}
                  ></Switch>
                )}
              </div>
            );
          }}
        />
        <Column
          title="Permissions"
          dataIndex="permissions"
          key="permissions"
          className="bg-white"
          width={"20%"}
          render={(_, data: any) => (
            <>
              {defaultRoles.includes(data.name) ||
              !permissions?.edit ||
              loggedInUserRoleId === data.id ? (
                <div
                  className={styles["dynamic-table__no-permissions"]}
                  onClick={() => fetchRolePermissions!(data)}
                >
                  <PermissionDetailsSvg />
                  <Text
                    underline
                    className={`${styles["dynamic-table__no-permissions--margin-left"]}`}
                  >
                    Permissions Details
                  </Text>
                </div>
              ) : (
                <div onClick={() => fetchRolePermissions!(data)}>
                  <PermissionDetailsSvg />
                  <Text
                    underline
                    onClick={permissionsHandler}
                    className={styles["dynamic-table__permissions"]}
                  >
                    Permissions Details
                  </Text>
                </div>
              )}
            </>
          )}
        />
        <Column
          title="Action"
          dataIndex="action"
          key="action"
          className="bg-white"
          width={"20%"}
          render={(value, data: any) => (
            <Space size={20}>
              {data.name == "Accountant" ||
              data.name === "Read Only" ||
              data.name === "Company Admin" ? (
                <>
                  <div
                    className={styles["dynamic-table__no-action"]}
                    style={{ marginRight: "2rem" }}
                  >
                    <EditActionSvg />
                  </div>
                  <div
                    className={styles["dynamic-table__no-action"]}
                    style={{ marginRight: "2rem" }}
                  >
                    <DeleteActionSvg />
                  </div>
                </>
              ) : (
                <>
                  {permissions?.edit && loggedInUserRoleId != data.id ? (
                    <div
                      className="cursor-pointer flex align-center justify-center"
                      onClick={() => editDataHandler(data)}
                      style={{ marginRight: "2rem" }}
                    >
                      <EditActionSvg />
                    </div>
                  ) : (
                    <div
                      className={styles["dynamic-table__no-action"]}
                      style={{ marginRight: "2rem" }}
                    >
                      <EditActionSvg />
                    </div>
                  )}
                  {permissions?.delete && loggedInUserRoleId != data.id ? (
                    <div
                      className="cursor-pointer flex align-center justify-center"
                      style={{ marginRight: "2rem" }}
                      onClick={() => {
                        deleteDataHandler(data);
                        showModal();
                      }}
                    >
                      <DeleteActionSvg />
                    </div>
                  ) : (
                    <div
                      className={styles["dynamic-table__no-action"]}
                      style={{ marginRight: "2rem" }}
                    >
                      <DeleteActionSvg />
                    </div>
                  )}
                </>
              )}
            </Space>
          )}
        />
      </Table>
    </div>
  );
};

export default DynamicTable;
