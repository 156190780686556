import React, { useEffect, useState } from 'react';
import { CssSpriteNames } from '../utils/staticObject';
import { apiCall } from '../api/api';
import "../assets/css/index.css"


interface CustomPageProps {
    id: string;
    activeIntegration: Array<any> | undefined;
}

interface selectedIntegrationInterface {
    id: string;
    integrationName: string;
}



const CustomPage: React.FC<CustomPageProps> = ({ id, activeIntegration }) => {

    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const [integrations, setIntegrations] = useState<any[]>([]);
    const [avalibleIntegrations, setAvalibleIntegrations] = useState<any[]>([])

    const [selectedIntegration, setSelectedIntegration] = useState<selectedIntegrationInterface>();

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                localStorage.setItem("t", id)

                const response: any = await apiCall("get", `/integrations/getActiveIntegration?companyId=${id}`);


                if (activeIntegration && activeIntegration?.length != 0) {
                    setAvalibleIntegrations(response.data);
                    const isAvailable = response.data.filter((item1: any) => {

                        return activeIntegration.some((item2: any) => item1.integrationName === item2.channelName)
                    }
                    );
                    setIntegrations(isAvailable);

                }
                else {

                    setIntegrations(response.data);
                }
                setError(null);
            } catch (err) {
                setError('Failed to fetch data');
            } finally {
                setLoading(false);
            }
        };

        if (id) {
            fetchData();
        }
    }, [id]);
    const connect = async () => {
        setLoading(true);
        localStorage.setItem("selectedIntegration", JSON.stringify(selectedIntegration));
        if (selectedIntegration?.integrationName === "XERO") {
            try {
                const responseData: any = await apiCall("get", `/xero/getxerourl/${selectedIntegration.id}`)

                if (responseData?.statusCode === 200) {
                    const authURL = responseData?.data;
                    // Open the new window
                    window.open(authURL, "_self");
                }

            } catch (error) {
                // console.error("Error fetching Xero URL:", error);

                // Handle the error here
            }
        }
        if (selectedIntegration?.integrationName === "QUICKBOOKS") {

            try {
                const responseData: any = await apiCall("get", `/qbo/authurl/${selectedIntegration.id}`)

                if (responseData?.statusCode === 200) {
                    const authURL = responseData?.data;
                    // Open the new window
                    window.open(authURL, "_self");
                }

            } catch (error) {
                console.error("Error fetching QBO URL:", error);

                // Handle the error here
            }
        }
        if (selectedIntegration?.integrationName === "ZOHOBOOK") {
            try {

                const responseData: any = await apiCall("get", `/zoho/authurl/${selectedIntegration.id}?type=zohoBook`)

                if (responseData?.statusCode === 200) {
                    const authURL = responseData?.data;
                    // Open the new window
                    window.open(authURL, "_self");
                }


            } catch (error) {
                console.error("Error fetching QBO URL:", error);

                // Handle the error here
            }
        }
        if (selectedIntegration?.integrationName === "WAVEACCOUNT") {
            try {
                const responseData: any = await apiCall("get", `/wave/authurl/${selectedIntegration.id}`)

                if (responseData?.statusCode === 200) {
                    const authURL = responseData?.data;
                    // Open the new window
                    window.open(authURL, "_self");
                }



            } catch (error) {
                console.error("Error fetching Xero URL:", error);

                // Handle the error here
            }
        }


    };

    const selectIntrgration = (integration: selectedIntegrationInterface) => {


        setSelectedIntegration(integration);
    };
    if (loading) return <p>Loading...</p>;
    if (error) return <p className="error">{error}</p>;

    return (
        loading ? <p>Loading...</p> :
            integrations && integrations.length ? <div className='container oAuth'>
                <h1>Select a software you wish to connect</h1>
                <p className="info-text">You will be prompted to sign in to your accounts to authorize the secure sharing of your financial information</p>

                <div className="software-grid grid-container">
                    {integrations && integrations.map((integration: any) => {

                        return <div className={selectedIntegration?.integrationName === integration.integrationName ? "grid-item-child active " : "grid-item-child"} onClick={() => selectIntrgration(integration)} key={integration.id}>
                            <div className={`grid-item bg-${CssSpriteNames[
                                integration.integrationName as keyof typeof CssSpriteNames
                            ]
                                }`}>

                            </div>
                        </div>
                    })}


                </div>
                <div className="click-button">
                    <button className='btn btn-primary' onClick={() => connect()}> connect</button>
                </div>

            </div>
                : avalibleIntegrations && avalibleIntegrations.length === 0 ? <h1>No integration is Active</h1> : <h1> Company is alredy connect</h1>
    );
};

export default CustomPage;
