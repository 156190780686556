import { createAsyncThunk } from '@reduxjs/toolkit';
import { getApi, postApi } from 'redux/apis';

export const SaveConnectionAction = createAsyncThunk(
    'saveconnection/save',
    async (values: any, { rejectWithValue }) => {
        try {

            const response = await postApi(`/saveconnection/save`, values);

            return response.data.data;
        } catch (error: any) {
            if (!error.response) {
                throw error;
            }
            return rejectWithValue(error?.response?.data);
        }
    }
);

export const getCurrentConnection = createAsyncThunk(
    "saveconnection",
    async (data: any, { rejectWithValue }) => {

        const { params } = data;
        try {
            const response = await getApi(
                `/saveconnection/getCurrent`,
                params
            );

            return response.data;
        } catch (error: any) {
            if (!error.response) {
                throw error;
            }
            return rejectWithValue(error?.response?.data);
        }
    }
);