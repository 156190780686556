import React from 'react';

export default function Subscription() {
  return (
    <div>
      {/* Subscription component */}
      Subscription
    </div>
  );
}
