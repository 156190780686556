import { TableActionHeader } from "components/Global";
import styles from "./index.module.scss"
import { CustomPage } from '../src'
import { useSelector } from "react-redux";
const UnifiedIntegration = () => {
    const { data, isLoading } = useSelector(
        (state: any) =>
            state.saveConnection);




    return (
        <div className={styles["role-table"]}>
            <TableActionHeader title={"Unified Interation"} />
            <div className={styles["connection-section"]} >
                {process.env.REACT_APP_COMPANYID ? isLoading ? "Loading" : <CustomPage id={process.env.REACT_APP_COMPANYID} activeIntegration={data && data} /> : "Please update company id in environment"}

            </div>

        </div>
    )
}

export default UnifiedIntegration;