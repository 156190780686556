import { createSlice } from "@reduxjs/toolkit";
import { SaveConnectionAction, getCurrentConnection } from "redux/action/saveConnectionAction"
const initialState: any = {
    data: null,
    isLoading: true,
    updateLoader: false,
    totalPages: 0,

    error: null,
};
const SaveConnectionSlice = createSlice({
    name: "saveConnection",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Fetch Profile
        builder.addCase(SaveConnectionAction.pending, (state) => {
            state.isLoading = true;

            state.error = null;
        });
        builder.addCase(SaveConnectionAction.fulfilled, (state, action) => {


            state.isLoading = false;
            state.data = action?.payload;

        });
        builder.addCase(SaveConnectionAction.rejected, (state, action: any) => {
            state.isLoading = false;
            state.error = action.payload;
            state.data = [];

        });



        builder.addCase(getCurrentConnection.pending, (state) => {
            state.isLoading = true;

            state.error = null;
        });
        builder.addCase(getCurrentConnection.fulfilled, (state, action) => {


            state.isLoading = false;
            state.data = action?.payload.data;

        });
        builder.addCase(getCurrentConnection.rejected, (state, action: any) => {
            state.isLoading = false;
            state.error = action.payload;
            state.data = [];

        });


    },
});
export default SaveConnectionSlice