import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "redux/store";
import ConnectedAppsTable from "components/settings/Integration/ConnectedApps";
import NewConnectionApp from "components/settings/Integration/NewConnection";
import { getActiveConnectionAction } from "redux/action/activeConnectionsActions";
import { ConnectedAppInterface } from "interfaces/connection.interfaces";

export default function Integrations() {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    // Fetch active connections when the component mounts
    dispatch(getActiveConnectionAction());
  }, []);

  //counting total apps
  const connectedAppsCount = useSelector(
    (state: ConnectedAppInterface) => state?.ActiveServices?.data?.length
  );

  return (
    <div>
      {connectedAppsCount > 0 ? (
        // Render the list of connected apps if there are any
        <ConnectedAppsTable />
      ) : connectedAppsCount <= 0 ? (
        // Render the "New Connection" component if there are no connected apps
        <NewConnectionApp />
      ) : (
        // Render the "Loader" if connected apps are loading
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "63vh",
          }}
        >
          <img
            src={`${process.env.REACT_APP_IMAGES_BASE_URL}/assets/gifs/loading-black.gif`}
            crossOrigin={
              process.env.REACT_APP_ENV === "local" ? undefined : "anonymous"
            }
            height={60}
          />
        </div>
      )}
    </div>
  );
}
