import { AuthLayout } from "components/Global/AuthLayout";
import GlobalLayout from "layouts/Global";
import { ForgotPassword, Home, Login, ResetPassword, BillPayment } from "pages";
import Roles from "pages/settings/roles";
import Settings from "pages/settings/settings";
import Users from "pages/settings/users";
import Register from "pages/Register";
import { createBrowserRouter } from "react-router-dom";
import Subcription from "pages/settings/subscription";
import IntegrationPage from "pages/settings/Integration";
import ConnectedApps from "pages/settings/Integration/ConnectedApps";
import EcommerceTable from "components/settings/Integration/Ecommerce";
import ERPTable from "components/settings/Integration/ERP";
import AccountingTable from "components/settings/Integration/Accouting";
import PaymentGatewayTable from "components/settings/Integration/PaymentGateway";
import MarketPlaceTable from "components/settings/Integration/MarketPlace";
import AccessDeniedPage from "pages/AccessDeniedPage";
import CRMTable from "components/settings/Integration/CRM";
import CallbackComponent from "components/settings/Integration/Accouting/AccountingCallback";
import CRMCallbackComponent from "components/settings/Integration/CRM/CRMCallback";
import EcommerceCallbackComponent from "components/settings/Integration/Ecommerce/EcommerceCallback";
import ERPCallbackComponent from "components/settings/Integration/ERP/ERPCallback";
import QuickbookDesktop from "components/Global/QuickbookDesktop";
import PaymentGatewayCallbackComponent from "components/settings/Integration/PaymentGateway/PaymentGatewayCallback/PaymentGatewayCallback";
import PageNotFoundPage from "pages/PageNotFound";
import Configurations from "pages/settings/Configurations";
import UnifiedIntegration from "pages/unifiedIntegration";
import CallBack from "pages/callBack";
// import CallbackComponentPage from "../components/src/pages/CallBack"

const router = createBrowserRouter([
  {
    element: <AuthLayout />,
    children: [
      {
        path: "/",
        element: <GlobalLayout />,
        children: [
          {
            index: true,
            element: <Home />,
          },
          {

            path: "AccountingExport",
            element: <BillPayment />,
          },
          {

            path: "fintech/callBack",
            element: <CallBack />,
          },
        ],
      },
      {
        path: "/settings",
        element: <Settings />,
        children: [
          {
            index: true,
            path: "users",
            element: <Users />,
          },
          {
            path: "roles",
            element: <Roles />,
          },
          {
            path: "integrations",
            element: <IntegrationPage />,
            children: [
              {
                index: true,
                path: "connectedApps",
                element: <ConnectedApps />,
              },
              // {
              //   path: "connectedApps",
              //   element: <ConnectedApps />,
              // },
              {
                path: "ecommerce",
                element: <EcommerceTable />,
              },
              {
                path: "erp",
                element: <ERPTable />,
              },
              {
                path: "accounting",
                element: <AccountingTable />,
              },
              {
                path: "paymentGateway",
                element: <PaymentGatewayTable />,
              },
              {
                path: "marketplace",
                element: <MarketPlaceTable />,
              },
              {
                path: "crm",
                element: <CRMTable />,
              },
              {
                path: "qbd",
                element: <QuickbookDesktop />,
              },
            ],
          },
          {
            path: "unifiedIntegration",
            element: <UnifiedIntegration />,
          },
          {
            path: "subscription",
            element: <Subcription />,
          },
          {
            path: "configurations",
            element: <Configurations />,
          },
        ],
      },
      {
        element: <Login />,
        path: "/login",
      },
      {
        path: "/register",
        element: <Register />,
      },

      {
        path: "/reset-password",
        element: <ResetPassword />,
      },
      {
        path: "/forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "/access-denied",
        element: <AccessDeniedPage />,
      },
      {
        path: "/qbo/callback",
        element: <CallbackComponent />,
      },
      {
        path: "/freshbook/callback",
        element: <CallbackComponent />,
      },
      {
        path: "/zoho/callback",
        element: <CallbackComponent />,
      },
      {
        path: "/bc/oauth",
        element: <EcommerceCallbackComponent />,
      },
      {
        path: "/force/callback",
        element: <CRMCallbackComponent />,
      },
      {
        path: "/xero/callback",
        element: <CallbackComponent />,
      },
      {
        path: "wave/callback",
        element: <CallbackComponent />,
      },
      {
        path: "/shopify/callback",
        element: <EcommerceCallbackComponent />,
      },
      {
        path: "/dynamic/callback",
        element: <ERPCallbackComponent />,
      },
      {
        path: "/stripe/callback",
        element: <PaymentGatewayCallbackComponent />,
      },
    ],
  },
  {
    path: "*",
    element: <PageNotFoundPage />,
  },
]);

export default router;
