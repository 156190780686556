import { useEffect, useState } from "react";
import { useSearchParams, useLocation } from "react-router-dom";
import styles from "./index.module.scss";
import { postApi } from "redux/apis";
import { v4 as uuidv4 } from "uuid";
import { toastText } from "utils/utils";

const CallbackComponent = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [queryParams] = useSearchParams();
  const location = useLocation();

  // Define an object to store callback-related data
  const callbackData = {
    uuid: uuidv4(),
    data: true,
    error: false,
  };

  // Define a function to handle API calls and error handling
  const handleApiCall = async (apiEndpoint: any, data: any) => {
    try {
      // Make an API request
      const res = await postApi(apiEndpoint, data);
      // Check if the response status is 200
      if (res?.status === 200) {
        // Store the callback data in local storage
        localStorage.setItem(
          "callBackUpdatedData",
          JSON.stringify(callbackData)
        );

        // Show a success toast message
        toastText("Connection Successful", "success");
      } else {
        // Throw an error if the API call is not successful
        throw new Error("Fail to Connect");
      }
    } catch (error: any) {
      if (error?.response?.status === 409) {
        // Store error data in local storage
        localStorage.setItem(
          "callBackUpdatedData",
          JSON.stringify({
            ...callbackData,
            error: true,
            message: error?.response?.data?.message,
          })
        );
      } else {
        // Handle API call errors
        // Store error data in local storage
        localStorage.setItem(
          "callBackUpdatedData",
          JSON.stringify({ ...callbackData, error: true })
        );
      }
    }
    // Close the window after the API call
    window.close();
  };

  useEffect(() => {
    const run = async () => {
      setIsLoading(true);

      // Check the current pathname to determine the callback logic
      if (location.pathname.startsWith("/freshbook/callback")) {
        await handleApiCall("/fresh-books/callback", {
          code: queryParams.get("code"),
        });
      } else if (location.pathname.startsWith("/qbo/callback")) {
        const url = window.location.href;
        await handleApiCall("/qbo/callback", { url });
      } else if (location.pathname.startsWith("/zoho/callback")) {
        await handleApiCall("/zoho/callback", {
          code: queryParams.get("code"),
        });
      } else if (location.pathname.startsWith("/xero/callback")) {
        const currentURL = window.location.href;
        const origin = window.location.origin;
        const url = currentURL.replace(origin, "");
        await handleApiCall("/xero/callback", { url });
      } else if (location.pathname.startsWith("/wave/callback")) {
        await handleApiCall("/wave/callback", {
          code: queryParams.get("code"),
        });
      }
      setIsLoading(false);
    };

    run();
  }, []);

  return (
    <div className={styles["main-container"]}>
      {isLoading && (
        <img
          src={`${process.env.REACT_APP_IMAGES_BASE_URL}/assets/gifs/loading.gif`}
          height={60}
          crossOrigin={
            process.env.REACT_APP_ENV === "local" ? undefined : "anonymous"
          }
        />
      )}
    </div>
  );
};

export default CallbackComponent;
