import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import ConfirmDelete from "components/Global/confirmDeleteModel";

import ConnectedTable from "./Table";
import styles from "./index.module.scss";
import { ConnectedAppInterface } from 'interfaces/connection.interfaces';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserProfileInterface } from 'interfaces/user.interface';
import { checkPermission } from 'utils/utils';


// Creating the list of user table
const ConnectedAppsTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const allActiveConnection = useSelector(
    (state: ConnectedAppInterface) => state?.ActiveServices?.data
  );
 
  // Handle the pagination for the table
  const paginationChangeHandler = (pageNo: number) => {
    setCurrentPage(pageNo);
  };


  //   For conform operation
  const handleOk = () => {
    setIsModalOpen(false);
  };

  //   For cancel operation
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // Delete
  const deleteHandler = () => {
    setIsModalOpen(false);
  };

  //   For open the model
  const showModal = () => {
    setIsModalOpen(true);
  };

  const location = useLocation();

  const navigate = useNavigate();

  const selectedCompanyPermission = useSelector((state: UserProfileInterface) => state?.userProfile?.data?.companies[0]?.role?.permissions)

  const hasViewPermission = checkPermission(selectedCompanyPermission, {
    permissionName: 'Integrations',
    permission: ['view'],
  });

  useEffect(() => {
    if (location.pathname.startsWith('/settings/integrations') && selectedCompanyPermission?.length) {
      if (!hasViewPermission) {
        navigate('/')
      }
    }

  }, [location, selectedCompanyPermission?.length]);

  // JSX
  return (
    <>
      <div className={styles["integration-table"]}>
        <div>
          <div>
            <ConnectedTable
              integrationDataSource={allActiveConnection}
              paginationChangeHandler={paginationChangeHandler}
              currentPage={currentPage}
              totalRecords={10}
              showModal={showModal}
            />
          </div>
        </div>
      </div>
      <ConfirmDelete
        handleCancel={handleCancel}
        handleOk={handleOk}
        isModalOpen={isModalOpen}
        deleteHandler={deleteHandler}
      />
    </>
  );
};

export default ConnectedAppsTable;
