import { LoginLayoutBody } from "components/Login";
import { LoginLayout } from "layouts";
import { FORMDATA } from "constants/Data";
import { loginAction } from "redux/action/loginAction";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getCompanies } from "redux/slice/companySlice";
import { useEffect, useState } from "react";
import { fetchProfileAction } from "redux/action/profileAction";
import { postApi } from "redux/apis";
import { toastText } from "utils/utils";
import ConfirmLoginOverride from "components/Global/confirmLoginOverride";

// Login page
const Login = () => {
  // React Hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const first = searchParams.get("first");
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isXeroLoading, setIsXeroLoading] = useState(false);
  const [submittedValues, setSubmittedValues] = useState(null);
  const [token, setToken] = useState(searchParams.get("token"));
  const [isIntuitLoading, setIsIntuitLoading] = useState(false);
  const [isPasswordValidate, setIsPasswordValidate] = useState(false);

  useEffect(() => {
    console.log("Calling");
    if ("serviceWorker" in navigator && navigator.serviceWorker.controller) {
      console.log("Service worker");
      navigator.serviceWorker.controller.postMessage("addToCache");
    }
  }, []);

  // Effect to handle email verification
  useEffect(() => {
    // If there's a token, handle token verification and further actions
    if (token) {
      setIsLoading(true);
      postApi(`/auth/verifyemail/${token}`)
        .then(() => {
          if (first) {
            toastText("Email verification successful", "success");
            setToken(null);
          }
          setIsLoading(false);
        })
        .catch((err) => {
          toastText(err?.response?.data?.message, "error");
          setIsLoading(false);
        });
    }
  }, [token, first]);

  //   For open the model
  const showModal = () => {
    setIsModalOpen(true);
  };

  // Close the modal
  const handleOk = () => {
    setIsModalOpen(false);
  };

  //   For cancel operation
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // Handle the proceed action after modal confirmation
  const proceedHandler = () => {
    if (submittedValues !== null) {
      const { email, password, rememberMe } = submittedValues;
      const proceedFlag = true; // Default to false
      const dataToSend = {
        email,
        password,
        rememberMe,
        confirmOverRide: proceedFlag,
      };
      if (isPasswordValidate) {
        setIsLoading(true);
        setIsXeroLoading(false);
        setIsIntuitLoading(false);
        setSubmittedValues(null);
        dispatch(loginAction(dataToSend) as any)
          .unwrap()
          .then((res: any) => {
            dispatch(fetchProfileAction() as any).then(() => {
              setIsLoading(false);
              setIsXeroLoading(false);
              setIsIntuitLoading(false);
              dispatch(getCompanies(res));
              navigate("/");
            });
          })
          .catch(() => {
            setIsLoading(false);
            setIsXeroLoading(false);
            setIsIntuitLoading(false);
            navigate("/login");
          });
      }
    }
  };

  const onSubmit = (values: any) => {
    if (isPasswordValidate) {
      setIsLoading(true);
      setIsXeroLoading(false);
      setIsIntuitLoading(false);
      setSubmittedValues(values);
      dispatch(loginAction(values) as any)
        .unwrap()
        .then((res: any) => {
          dispatch(fetchProfileAction() as any).then(() => {
            setIsLoading(false);
            setIsXeroLoading(false);
            setIsIntuitLoading(false);
            dispatch(getCompanies(res));
            navigate("/");
          });
        })
        .catch((error: any) => {
          if (error?.error?.status === 409) {
            showModal();
          }
          setIsLoading(false);
          setIsXeroLoading(false);
          setIsIntuitLoading(false);
          navigate("/login");
        });
    }
  };

  // JSX for the Login page
  return (
    <LoginLayout>
      <LoginLayoutBody
        title="Log in"
        description="<p>
							Welcome to <strong> Animal Planet! </strong>Please Enter your
							Details.
						</p>"
        formData={FORMDATA.loginFields}
        buttonTitle={"Sign in"}
        action={loginAction}
        redirectUrl="/forgot-password"
        redirectText="Forgot password?"
        btnIntuit={"Sign in with Intuit"}
        btnXero={"Sign in with Xero"}
        rememberMe={"Remember me"}
        onSubmit={onSubmit}
        isLoading={isLoading}
        isIntuitLoading={isIntuitLoading}
        isXeroLoading={isXeroLoading}
        accountText={" Don't have an account yet?"}
        accountUrl={"Sign up Today!"}
        setIsPasswordValidate={setIsPasswordValidate}
      ></LoginLayoutBody>
      <ConfirmLoginOverride
        handleCancel={handleCancel}
        handleOk={handleOk}
        isModalOpen={isModalOpen}
        proceedHandler={proceedHandler}
        isLoading={isLoading}
      />
    </LoginLayout>
  );
};

export default Login;
